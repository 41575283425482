import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import configureStore from 'store/index'

import App from './components/App/App'

import { env, sentry } from './config'

export const store = configureStore()

Sentry.init({
  dsn: sentry.url,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
    Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV === 'development' ? 'development' : env,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: sentry.tracesSampleRate,
  replaysSessionSampleRate: sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
})

const initializeApplication = async () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App store={store} />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  )
}

initializeApplication()

export default store
