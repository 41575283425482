const { UNIVERSES_SUB_DOMAIN, UNIVERSES } = require('../constants/routes')

const ENVS = ['qa', 'qa1', 'preprod', 'local', 'mocks']

const getMocksInfos = infos => {
  const winLocSearch =
    typeof window === 'undefined' ? null : window?.location?.search
  const urlParams = winLocSearch ? new URLSearchParams(winLocSearch) : null
  const subDomainParam = urlParams === null ? null : urlParams.get('subDomain')
  const subDomain =
    subDomainParam === null
      ? UNIVERSES_SUB_DOMAIN[UNIVERSES.PARTNER]
      : subDomainParam

  return {
    ...infos,
    env: 'mocks',
    universe: Object.keys(UNIVERSES_SUB_DOMAIN).find(
      key => UNIVERSES_SUB_DOMAIN[key] === subDomain,
    ),
    subDomain,
  }
}

const getMocksDomain = () => process.env.REACT_APP_MOCK_HOST ?? 'mock-server'

export const getInfosFromHostname = () => {
  const infos = { env: undefined, subDomain: undefined, universe: undefined }

  if (typeof window === 'undefined') {
    return getMocksInfos(infos)
  }

  const hostname = window.location.hostname

  if (hostname === getMocksDomain()) {
    return getMocksInfos(infos)
  }

  const searchRes = hostname.match(
    new RegExp(`(\\w+)\\.((\\w+)\\.)?quotatis\\.fr`),
  )

  if (searchRes !== null) {
    const [ignored1, parsedSubDomainRes, ignored2, parsedEnv] = searchRes
    const env = ENVS.find(el => el === parsedEnv) ?? 'prod'

    return {
      ...infos,
      env,
      subDomain: parsedSubDomainRes,
      universe: Object.keys(UNIVERSES_SUB_DOMAIN).find(
        key => UNIVERSES_SUB_DOMAIN[key] === parsedSubDomainRes,
      ),
    }
  }

  console.warn('Unable to determine infos from hostname')

  return infos
}

export const getApiEnvFromHostname = () => {
  const env = getInfosFromHostname().env

  return env === 'local' ? 'qa' : env
}

export const isApiEnvProd = () => getApiEnvFromHostname() === 'prod'

export const getGtmEvtLink = path => {
  const subDomain = getInfosFromHostname().subDomain

  return `${subDomain !== null ? `/${subDomain}` : ''}${path}`
}

const getPort = env => {
  if (env === 'local') {
    return process.env.PORT || '4433'
  }

  if (env === 'mocks') {
    return process.env.REACT_APP_DEVSRV_PORT || '4321'
  }

  return null
}

const DOMAINS_WITHOUT_ENV_PART = ['prod', 'mocks']

export const buildNewUrl = (env, subdomain, href) => {
  const scheme = env === 'mocks' ? 'http' : 'https'
  const domainAndTld = env === 'mocks' ? getMocksDomain() : 'quotatis.fr'
  const envPart = DOMAINS_WITHOUT_ENV_PART.includes(env) ? '' : `${env}.`
  const port = getPort(env)
  const portPart = port === null ? '' : `:${port}`
  const hostname =
    env === 'mocks' ? domainAndTld : `${subdomain}.${envPart}${domainAndTld}`
  const queryParamPart = env === 'mocks' ? `?subDomain=${subdomain}` : ''

  return `${scheme}://${hostname}${portPart}${
    href !== '/' ? href : ''
  }${queryParamPart}`
}
/**
 * @param {string} url
 * @returns {boolean}
 */
export const isYousignV2Url = url => url.search(/yousign\.app/g) === -1
