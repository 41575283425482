import { getProSignUpPackageConfirmationPath } from 'routes'

export const CHANGE_PRO_PWD_FORM_NAME = 'ChangeProPwdForm'
export const ONBOARDING_FORM_NAME = 'OnboardingForm'
export const MY_ACCOUNT_FORM_NAME = 'MyAccountForm'
export const MY_ACCOUNT_PROFILES_FORM_NAME = 'MyAccountProfilesForm'
export const MY_NEWSLETTER_PREF_FORM_NAME = 'MyNewsletterPreferenceForm'
export const SURVEY_FORM_NAME = 'SurveyForm'
export const PRO_REGISTRATION_FORM_NAME = 'ProRegistrationForm'
export const PRO_PARTNER_REGISTRATION_FORM_NAME = 'ProPartnerRegistrationForm'
export const PRO_PACKAGE_CONTRACT_FORM_NAME = 'ProPackageContractForm'
export const PRO_PACKAGE_NEW_PASSWORD = 'ProPackageNewPassword'
export const PRO_PACKAGE_CONTRACT_COMPANY_FORM_NAME =
  'ProPackageContractCompanyForm'
export const PRO_PACKAGE_CONTRACT_SINGLE_FORM_NAME =
  'ProPackageContractSingleForm'

export const PRO_ACC_NAV_DISPLAY_FLAG = 'proAccNavDisplayed'
export const PRO_FIELD_COMPANYNAME = 'name'
export const PRO_FIELD_SIRET = 'siret'
export const PRO_FIELD_POSTALCODE = 'postalCode'
export const PRO_FIELD_CITY = 'city'
export const PRO_FIELD_TRADE = 'trade'
export const PRO_FIELD_GENDER = 'gender'
export const PRO_FIELD_LASTNAME = 'lastName'
export const PRO_FIELD_FIRSTNAME = 'firstName'
export const PRO_FIELD_PHONE = 'phone'
export const PRO_FIELD_EMAIL = 'email'
export const PRO_FIELD_PASSWORD = 'password'
export const PRO_FIELD_LOCALISATION = 'localisation'
export const PRO_FIELD_LNG = 'lng'
export const PRO_FIELD_LAT = 'lat'
export const PRO_FIELD_COMPANY_URL_PARAM = 'company'
export const PRO_FIELD_LASTNAME_URL_PARAM = 'lastname'
export const PRO_FIELD_FIRSTNAME_URL_PARAM = 'firstname'
export const PRO_FIELD_PHONE_URL_PARAM = 'phone'
export const PRO_FIELD_GENDER_URL_PARAM = 'gender'
export const PRO_REGISTRATION_PREFIX = 'freemium_registration'
export const NEW_FIRM_USER = 'new_user'
export const PRO_PACKAGES_REGISTRATION_PREFIX = 'freemium_package_registration'
export const PRO_PACKAGE_FIRM_TYPE_SINGLE = 1
export const PRO_PACKAGE_FIRM_TYPE_COMPANY = 2

export const PREFIX_CATEGORIES = {
  [PRO_REGISTRATION_PREFIX]: PRO_REGISTRATION_FORM_NAME,
  [PRO_PACKAGES_REGISTRATION_PREFIX]: 'PackageRegistrationForm',
}

export const PRO_REGISTRATION_STEP_ONE = [
  PRO_FIELD_COMPANYNAME,
  PRO_FIELD_SIRET,
  PRO_FIELD_POSTALCODE,
  PRO_FIELD_CITY,
  PRO_FIELD_TRADE,
]

export const PRO_REGISTRATION_STEP_TWO = [
  PRO_FIELD_GENDER,
  PRO_FIELD_LASTNAME,
  PRO_FIELD_FIRSTNAME,
  PRO_FIELD_PHONE,
  PRO_FIELD_EMAIL,
  PRO_FIELD_PASSWORD,
]

export const PRO_FIELDS = {
  [PRO_FIELD_EMAIL]: PRO_FIELD_EMAIL,
  [PRO_FIELD_LOCALISATION]: PRO_FIELD_LOCALISATION,
  [PRO_FIELD_SIRET]: PRO_FIELD_SIRET,
  [PRO_FIELD_COMPANY_URL_PARAM]: PRO_FIELD_COMPANYNAME,
  [PRO_FIELD_FIRSTNAME_URL_PARAM]: PRO_FIELD_FIRSTNAME_URL_PARAM,
  [PRO_FIELD_LASTNAME_URL_PARAM]: PRO_FIELD_LASTNAME_URL_PARAM,
  [PRO_FIELD_PHONE_URL_PARAM]: PRO_FIELD_PHONE_URL_PARAM,
  [PRO_FIELD_GENDER_URL_PARAM]: PRO_FIELD_GENDER_URL_PARAM,
}

export const PRO_SIGNUP_QP_API_FORM_MAPPING = [
  { apiField: 'trade', formField: PRO_FIELD_TRADE },
  { apiField: 'postalCode', formField: PRO_FIELD_LOCALISATION },
  { apiField: 'name', formField: 'companyName' },
  { apiField: 'civility', formField: PRO_FIELD_GENDER },
  { apiField: 'firstName', formField: PRO_FIELD_FIRSTNAME },
  { apiField: 'lastName', formField: PRO_FIELD_LASTNAME },
  { apiField: 'email', formField: PRO_FIELD_EMAIL },
  { apiField: 'tel', formField: PRO_FIELD_PHONE },
]

export const PRO_SIGNUP_API_FORM_MAPPING = [
  { apiField: 'firm.name', formField: PRO_FIELD_COMPANYNAME },
  { apiField: 'firm.registrationNumber', formField: PRO_FIELD_SIRET },
  { apiField: 'firm.postalCode', formField: PRO_FIELD_POSTALCODE },
  { apiField: 'firm.city', formField: PRO_FIELD_CITY },
  { apiField: 'firm.trades', formField: PRO_FIELD_TRADE },
  { apiField: 'user.gender', formField: PRO_FIELD_GENDER },
  { apiField: 'user.lastName', formField: PRO_FIELD_LASTNAME },
  { apiField: 'user.firstName', formField: PRO_FIELD_FIRSTNAME },
  { apiField: 'user.mobilePhone', formField: PRO_FIELD_PHONE },
  { apiField: 'user', formField: PRO_FIELD_EMAIL },
  { apiField: 'user.password', formField: PRO_FIELD_PASSWORD },
  { apiField: 'user.fixedPhone', formField: PRO_FIELD_PHONE },
]

export const PRO_CONTACT_API_FORM_MAPPING = [
  { apiField: 'city', formField: PRO_FIELD_CITY },
  { apiField: 'postalCode', formField: PRO_FIELD_POSTALCODE },
  { apiField: 'firstName', formField: PRO_FIELD_FIRSTNAME },
  { apiField: 'lastName', formField: PRO_FIELD_LASTNAME },
  { apiField: 'phone', formField: PRO_FIELD_PHONE },
  { apiField: 'email', formField: PRO_FIELD_EMAIL },
  { apiField: 'content', formField: 'content' },
  { apiField: 'captcha', formField: 'captcha' },
]

export const PRO_PACKAGE_LEGAL_INDIVIDUAL = 'EI'
export const PRO_PACKAGE_LEGAL_COMPANY_DEFAULT = 'EURL'

export const PRO_PACKAGE_LEGAL = {
  eurl: 'EURL',
  sarl: 'SARL',
  sas: 'SAS',
  sasu: 'SASU',
  sa: 'SA',
  sca: 'SCA',
  sccv: 'SCCV',
  sci: 'SCI',
  scm: 'SCM',
  scp: 'SCP',
  scs: 'SCP',
  sep: 'SEP',
  snc: 'SNC',
  ca: 'CA',
  rcs: 'SE',
}

export const CONFIRMATION_PATHS_FROM_PREFIX = {
  [PRO_PACKAGES_REGISTRATION_PREFIX]: getProSignUpPackageConfirmationPath,
}

export const YOUSIGN_IFRAME_CONTAINER_ID = 'yousign-iframe-container'
