import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import notify from 'sagas/notify'
import {
  GET_ONBOARDING_QUESTIONS,
  SUBMIT_ONBOARDING_FORM,
  submitOnboardingForm,
  getOnboardingQuestions,
  firmDetails,
  GET_PACKAGES_LIST,
  GET_DEPARTMENTS_LIST,
  getDepartmentsList,
  SET_CONFIGURATION,
  setConfiguration,
  saveFirmComment,
  GET_PACKAGE_SUBSCRIPTIONS,
  getHasPackageSubscription,
} from 'store/actions'
import { packagesIndex } from 'services/algolia'
import { fromPro, fromContext, fromFirm, fromRouting } from 'store/selectors'
import handleRequest from 'sagas/handleRequest'
import client from 'services/httpClient/commonClient'
import { dataGouvEnterpriseClient } from 'services/httpClient/dataGouvClient'
import { getUrlCandidatePackage } from 'utils/pro/proTabs'
import translateFactory from 'utils/translateFactory'
import each from 'lodash/each'
import { change } from 'redux-form'
import {
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TYPE_TEXT,
} from 'constants/form'
import { PRO_API } from 'constants/services'
import { CONFIGURATION_PACKAGE_FORM, PRODUCT_TYPES } from 'constants/onboarding'
import { store } from '../../index'

const urlPrefix = '/api'

function* handleOnboardingQuestions() {
  try {
    yield* handleRequest({
      requestActions: getOnboardingQuestions,
      promise: call(
        client(PRO_API, true).get,
        `${urlPrefix}/onboarding_questions`,
      ),
    })
  } catch (e) {
    yield put(getOnboardingQuestions.failure())

    throw e
  }
}

function* handleSubmitConfigurationForm({ payload }) {
  const currentFirmIri = yield select(fromPro.getFirmId)

  let productSubscriptions = []
  const packagesValues = payload.packages
  const packagesSelected = Object.keys(packagesValues).map(pk => {
    if (typeof packagesValues[pk] === 'object') {
      return packagesValues[pk]
    }
    return false
  })

  packagesSelected.forEach(pk => {
    if (typeof pk === 'object') {
      Object.keys(pk).forEach(options => {
        if (pk[options] !== false) {
          productSubscriptions.push({ product: `/api/products/${options}` })
        }
      })
    }
  })

  productSubscriptions = [
    ...productSubscriptions,
    ...(payload.previousProductSubscriptions ?? []).map(product => ({
      product,
    })),
  ]

  const {
    postcodes,
    matchingOption,
    matchingAddress,
    matchingRadius,
    matchingPoint,
  } = payload

  const firmId = yield select(fromPro.getFirmId)

  const firmProductSubscriptionId = yield select(
    fromFirm.getFirmProductSubscriptionId,
    firmId,
  )

  const firmLegalDataId = yield select(fromFirm.getFirmLegalDataId, firmId)

  const firmLegalData = {
    '@id': firmLegalDataId,
    internationalBankAccountNumber: payload.internationalBankAccountNumber,
    swiftCode: payload.swiftCode,
    vatNumber: payload.vatNumber,
    subjectToVat: payload.subjectToVat === 'true',
  }

  if (payload.internationalBankAccountNumber) {
    const language = yield select(fromContext.getLang)
    const translate = translateFactory(language)
    yield* handleRequest({
      requestActions: saveFirmComment,
      promise: call(client(PRO_API, true).post, '/api/firm_comments', {
        firm: currentFirmIri,
        comment: translate('firm.comments.IBAN_modifications'),
      }),
    })
  }

  yield* handleRequest({
    requestActions: setConfiguration,
    promise: call(client(PRO_API, true).put, firmId, {
      firmProductSubscription: {
        '@id': firmProductSubscriptionId,
        productSubscriptions,
        postcodes,
        matchingOption,
        matchingAddress,
        matchingRadius,
        matchingPoint,
      },
      firmLegalData,
    }),
  })

  yield put(firmDetails.request({ id: firmId }))
}

function* handleSubmitOnboardingForm({ data, props }) {
  const { onboardingQuestions } = props

  const answers = []

  try {
    onboardingQuestions.map(onboardingQuestion => {
      const { type } = onboardingQuestion
      const value = data[onboardingQuestion.systemKey] || null
      const onboardingQuestionId = onboardingQuestion['@id']

      switch (type) {
        case QUESTION_MULTIPLE_CHOICE:
          each(value, val => {
            answers.push({
              question: onboardingQuestionId,
              answer: val ? `/api/onboarding_answers/${val}` : null,
            })
          })
          break
        case QUESTION_SINGLE_CHOICE:
          answers.push({
            question: onboardingQuestionId,
            answer: value ? `/api/onboarding_answers/${value}` : null,
          })
          break
        case QUESTION_TYPE_TEXT:
          answers.push({
            question: onboardingQuestionId,
            answerText: value,
          })
        // eslint-disable-next-line no-fallthrough
        default:
          console.warn(`Nothing for ${onboardingQuestionId}`)
          break
      }

      return answers
    })
  } catch (e) {
    yield put(getOnboardingQuestions.failure())
  }

  try {
    yield* handleRequest({
      requestActions: submitOnboardingForm,
      promise: call(
        client(PRO_API, true).post,
        `${urlPrefix}/onboarding_answered_quizzes`,
        {
          answers,
        },
      ),
    })
  } catch (e) {
    yield put(getOnboardingQuestions.failure())

    // throw e
  }
  const firmId = yield select(fromPro.getFirmId)

  yield put(firmDetails.request({ id: firmId }))
}

function* handleSubmitOnboardingFormFailure() {
  yield* notify('', 'firm_legal.save.failure.message', 'error')
}
function* handleSubmitOnboardingFormSuccess() {
  yield* notify(
    '',
    'pro.package_candidate_document.saveUploadFileSuccess',
    'success',
  )
}
function* searchInPackagesIndex(query, { hitsPerPage = 1000 }) {
  const country = yield select(fromContext.getCountry)
  const firmId = yield select(fromPro.getFirmId)

  const response = yield call(() =>
    packagesIndex(country).search({
      hitsPerPage,
      facetFilters: [[`product_type:${PRODUCT_TYPES.package}`]],
    }),
  )

  const packagesByCategory = response.hits.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.categories[0]]: [
        ...(accumulator[currentValue.categories[0]] || []),
        {
          label: currentValue.name,
          systemKey: currentValue.code,
          typeCertificates: currentValue.required_certificate,
        },
      ],
    }),
    [],
  )

  yield put({ type: GET_PACKAGES_LIST.SUCCESS, packagesByCategory })
}

function* handleOnboardinPackages() {
  yield* searchInPackagesIndex('', { hitsPerPage: 200 } || {})
}

function* handleOnboardinDepartments({ perPage = 200 }) {
  const countryCode = yield select(fromContext.getCountry)

  yield* handleRequest({
    requestActions: getDepartmentsList,
    promise: call(
      client(PRO_API, true).get,
      `${urlPrefix}/departments?countryCode=${countryCode}&perPage=${perPage}`,
    ),
  })
}

function* handlePackageSubscriptions({ firm }) {
  yield* handleRequest({
    requestActions: getHasPackageSubscription,
    promise: call(
      client(PRO_API, true).get,
      `${firm}/has-package-subscriptions`,
    ),
  })
}

function* handleSetConfigurationSuccess() {
  const pathName = yield select(fromRouting.getPathname)
  const state = store.getState()
  const firm = { firm: fromPro.getFirmId(state) }

  yield handlePackageSubscriptions(firm)

  yield put(push(getUrlCandidatePackage('finished', true, pathName)))
}

function* handleGetVatNumber({ payload }) {
  yield* handleRequest({
    requestActions: getVatNumber,
    promise: call(
      dataGouvEnterpriseClient.get,
      `sirene/v3/etablissements/${payload}`,
    ),
    checkTokens: false,
  })
}

function* handleGetVatNumberSuccess({ payload }) {
  yield put(
    change(
      CONFIGURATION_PACKAGE_FORM,
      'vatNumber',
      payload?.etablissement?.unite_legale?.numero_tva_intra || '',
    ),
  )
}

export default function* () {
  yield all([
    takeLatest(GET_PACKAGES_LIST.REQUEST, handleOnboardinPackages),
    takeLatest(GET_PACKAGE_SUBSCRIPTIONS.REQUEST, handlePackageSubscriptions),
    takeLatest(GET_DEPARTMENTS_LIST.REQUEST, handleOnboardinDepartments),
    takeLatest(GET_ONBOARDING_QUESTIONS.REQUEST, handleOnboardingQuestions),
    takeLatest(SUBMIT_ONBOARDING_FORM.REQUEST, handleSubmitOnboardingForm),
    takeLatest(SET_CONFIGURATION.REQUEST, handleSubmitConfigurationForm),
    takeLatest(
      [SUBMIT_ONBOARDING_FORM.FAILURE, SET_CONFIGURATION.FAILURE],
      handleSubmitOnboardingFormFailure,
    ),
    takeLatest(
      [SUBMIT_ONBOARDING_FORM.SUCCESS, SET_CONFIGURATION.SUCCESS],
      handleSubmitOnboardingFormSuccess,
    ),
    takeLatest(SET_CONFIGURATION.SUCCESS, handleSetConfigurationSuccess),
  ])
}
